<template>
  <div ref="uploader"></div>
</template>

<script>
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import { v4 as uuidv4 } from 'uuid'
import { createNamespacedHelpers } from 'vuex'

const S3Module = createNamespacedHelpers('s3')

export default {
  name: 'S3FileUploader',
  props: {
    buttonText: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
    bucketName: {
      type: String,
      required: true,
    },
    keyPath: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
    },
  },
  watch: {
    buttonText() {
      this.$refs.uploader.querySelector('button').innerText = this.buttonText
    },
  },
  methods: {
    ...S3Module.mapActions(['uploadFile']),
    async uploadSelectedFile(file) {
      const button = this.$refs.uploader.querySelector('button')
      button.classList.add('is-loading')
      button.disabled = true

      try {
        const name = this.fileName || uuidv4()
        const fullName = `${name}.${file.extension}`
        const bucketParams = {
          Bucket: this.bucketName,
          Key: `${this.keyPath}/${fullName}`,
          Body: file.data,
          ContentType: file.type,
        }

        const response = await this.uploadFile(bucketParams)
        const fileUrlData = { location: response.Location, fileName: `${fullName}` }

        this.$refs.uploader.querySelector('button').classList.add('is-loading')

        this.$emit('uploaded', fileUrlData)
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Failed to upload file. Please try again.',
        })
      }

      button.classList.remove('is-loading')
      button.disabled = false
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['image/*', 'text/csv'],
      },
      onBeforeFileAdded: (file) => {
        this.uploadSelectedFile(file)
      },
    })

    uppy.use(FileInput, {
      target: this.$refs.uploader,
      locale: {
        strings: {
          chooseFiles: this.buttonText,
        },
      },
    })

    this.$refs.uploader
      .querySelector('button')
      .classList.add('btn', this.buttonClass || 'btn-white')
  },
}
</script>

<style scoped lang="scss">
::v-deep .btn-white {
  border-color: #d2ddec;
}
</style>
