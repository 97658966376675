<template>
  <div>
    <label class="mb-1">{{ title }}</label>
    <small class="form-text text-muted">{{ subtitle }}</small>
    <div class="row">
      <div class="col">
        <input
          type="text"
          v-model="promoCode"
          @input="setPromoCode"
          :placeholder="placeholder"
          class="form-control"
          :disabled="promoCodesAlreadyUploaded"
        />
      </div>
      <div class="col-auto pt-2 px-5">or</div>
      <div class="col-auto">
        <S3FileUploader
          @uploaded="fileUploaded"
          :button-text="uploadButtonText"
          :bucketName="bucketName"
          :keyPath="'campaigns/promo-codes'"
          button-class="btn-primary"
        />
      </div>
    </div>
    <div v-if="type === 'off-premise'" class="row mt-3">
      <div class="col">
        <label class="mb-1 mt-3">Off-Premise URL</label>
        <small class="form-text text-muted">
          Redirect customers to this URL after they receive a code.
        </small>
        <input
          type="text"
          v-model="offPremiseLocationUrl"
          @input="setOffPremiseUrl"
          placeholder="https://company.com/order/online"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<script>
import S3FileUploader from '@/components/MainContent/S3FileUploader'
import Config from '@/config'
import { createNamespacedHelpers } from 'vuex'

const PromoModuleV2 = createNamespacedHelpers('promoV2')

export default {
  name: 'PromoCodeForm',
  props: ['type', 'placeholder'],
  components: {
    S3FileUploader,
  },
  data: () => ({
    promoCode: '',
    offPremiseLocationUrl: '',
  }),
  computed: {
    ...PromoModuleV2.mapGetters(['selectPromo']),
    bucketName() {
      return `ovation-secure-${Config.isTest ? 'sandbox' : process.env.NODE_ENV}`
    },
    title() {
      const typeText = this.type === 'in-store' ? 'In-Store' : 'Off-Premise'
      return `${typeText} Promo Code`
    },
    promoCodesAlreadyUploaded() {
      return (
        (this.type === 'in-store' && this.selectPromo.hasUniqueInStoreCodes) ||
        (this.type === 'off-premise' && this.selectPromo.hasUniqueOffPremiseCodes)
      )
    },
    subtitle() {
      if (this.promoCodesAlreadyUploaded) {
        return 'Promo codes have already been uploaded. You may upload additional codes if needed.'
      }

      const typeText = this.type === 'in-store' ? 'in-store' : 'off-premise'
      return `Add an ${typeText} promo code to this offer or upload a CSV file of unique codes.`
    },
    uploadButtonText() {
      if (
        (this.type === 'in-store' && this.selectPromo.inStorePromoFile) ||
        (this.type === 'off-premise' && this.selectPromo.offPremisePromoFile)
      ) {
        return 'Upload Again'
      } else if (this.promoCodesAlreadyUploaded) {
        return 'Upload Additional Codes'
      } else {
        return 'Upload Promo Codes'
      }
    },
  },
  methods: {
    ...PromoModuleV2.mapActions(['setPromo']),
    setPromoCode() {
      if (this.type === 'in-store') {
        this.setPromo({ ...this.selectPromo, inStorePromoFile: undefined })
        this.setPromo({ ...this.selectPromo, inStorePromoCode: this.promoCode })
      } else {
        this.setPromo({ ...this.selectPromo, offPremisePromoFile: undefined })
        this.setPromo({ ...this.selectPromo, offPremisePromoCode: this.promoCode })
      }
    },
    setOffPremiseUrl() {
      this.setPromo({ ...this.selectPromo, offPremiseLocationUrl: this.offPremiseLocationUrl })
    },
    fileUploaded(fileUrlData) {
      const { fileName } = fileUrlData
      this.promoCode = ''
      if (this.type === 'in-store') {
        this.setPromo({ ...this.selectPromo, inStorePromoCode: undefined })
        this.setPromo({ ...this.selectPromo, inStorePromoFile: fileName })
      } else {
        this.setPromo({ ...this.selectPromo, offPremisePromoCode: undefined })
        this.setPromo({ ...this.selectPromo, offPremisePromoFile: fileName })
      }
    },
  },
  mounted() {
    this.offPremiseLocationUrl = this.selectPromo.offPremiseLocationUrl

    if (this.type === 'in-store') {
      this.promoCode = this.selectPromo.inStorePromoCode
    } else {
      this.promoCode = this.selectPromo.offPremisePromoCode
    }
  },
}
</script>
